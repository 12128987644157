import { Box } from '@mui/material';
import { useTableNoDataStyles } from 'modules/common/components/table-no-data/table-no-data.styles';
import { ReactElement, ReactNode } from 'react';

interface TableNoDataInterface {
  title?: ReactNode;
  children?: ReactNode;
}

export const TableNoData = ({ title = 'No records to show', children }: TableNoDataInterface): ReactElement => {
  const classes = useTableNoDataStyles();
  return (
    <tbody>
      <tr>
        <td colSpan={100}>
          <Box className={classes.container}>
            <h3 className={classes.heading}>{title}</h3>
            {children}
          </Box>
        </td>
      </tr>
    </tbody>
  );
};
