import { Theme } from '@mui/material';
import { roqMakeStyles } from 'modules/common/utils';
import { ThemeEnum } from 'modules/theme/enums';

export const useTablePaginationNumberedStyles = roqMakeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#f8fafb',
    paddingBottom: '12px',
    color: theme.palette.mode === ThemeEnum.LIGHT ? theme.palette.text.secondary : theme.palette.common.white,
    '& .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected': {
      backgroundColor: theme.palette.mode === ThemeEnum.LIGHT ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.15)',
    },
    '& .MuiButtonBase-root.MuiPaginationItem-root': {
      color: theme.palette.mode === ThemeEnum.LIGHT ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.87)',
    },
    '& svg': {
      margin: '12px !important'
    },
    '& .MuiSelect-iconStandard': {
      width: '22px',
      height: '22px',
      top: '0 !important'
    },
    '& .MuiTablePagination-selectIcon': {
      margin: '0px !important'
    },
    '& *': {
      fontSize: '12px !important',
      fontWeight: 400,
      lineHeight: '166%',
      letterSpacing: '0.4px',
    },
    '& p:first-of-type': {
      color: '#626262'
    }
  },
}));
