import { Grid, TablePagination } from '@mui/material';
import { TablePaginationActionComponent } from 'modules/common/components/table-pagination-action/table-pagination-action.component';
import { useTablePaginationNumberedStyles } from 'modules/common/components/table-pagination-numbered/table-pagination-numbered.styles';
import React, { ChangeEvent, FunctionComponent, ReactElement } from 'react';
interface Localization {
  firstTooltip?: string;
  previousTooltip?: string;
  nextTooltip?: string;
  lastTooltip?: string;
  labelDisplayedRows?: string;
  labelRowsPerPage?: string;
}

interface TablePaginationNumberedProps {
  onPageChange?: (pageSize: number, newPage: number) => void;
  onRowsPerPageChange?: (event: ChangeEvent) => void;
  page?: number;
  count?: number;
  rowsPerPage?: number;
  classes?: unknown;
  localization?: Localization;
  theme?: unknown;
  showFirstLastPageButtons?: boolean;
}

export const TablePaginationNumbered: FunctionComponent<TablePaginationNumberedProps> = (props): ReactElement => {
  const classes = useTablePaginationNumberedStyles();
  const { count, onPageChange, rowsPerPage, page, onRowsPerPageChange  } = props;

  const handleChange = (_, value: number) => {
    onPageChange(rowsPerPage, value);
  };

  const handleRowsPerPageChange = (evt: ChangeEvent) => {
    onRowsPerPageChange(evt);
  };

  return (
    <Grid container item justifyContent="end" className={classes.root}>
      <TablePagination
        count={count}
        page={page}
        onPageChange={handleChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        
        ActionsComponent={() => <TablePaginationActionComponent count={count} onPageChange={onPageChange} rowsPerPage={rowsPerPage} page={page} />}
      />
    </Grid>
  );
};
