import { IconButton, Stack, Typography } from '@mui/material';
import { CaretDoubleLeft, CaretDoubleRight, CaretLeft, CaretRight } from '@phosphor-icons/react';
import React, { ReactElement } from 'react';

interface ITablePaginationActionComponentProps {
  page: number,
  count: number,
  rowsPerPage: number,
  onPageChange: (rows: number, value: number) => void
}

export const TablePaginationActionComponent = (props: ITablePaginationActionComponentProps): ReactElement => {
  const { page, count, rowsPerPage, onPageChange } = props
  const totalPages = Math.ceil(count / rowsPerPage)

  const pageChangeHandler = (value: number) => {
    if (value >= 0 && value <= totalPages) {
      onPageChange(rowsPerPage, value);
    }
  };

  return (
    <Stack direction={'row'} alignItems={'center'} marginLeft={'2px'} spacing={-1} >
      <IconButton aria-label="First Page"
        onClick={() => pageChangeHandler(0)}
        color='inherit'
        disabled={page === 0}
      >
        <CaretDoubleLeft size={20} />
      </IconButton>
      <IconButton aria-label="Previous Page"
        onClick={() => pageChangeHandler(page - 1)}
        disabled={page === 0}
        color='inherit'
      >
        <CaretLeft size={20} />
      </IconButton>
      <Typography component={'div'} color='inherit'>
        <span color='inherit'>{`${page + 1}/${totalPages}`}</span>
      </Typography>
      <IconButton aria-label="Next Page"
        onClick={() => pageChangeHandler(page + 1)}
        color='inherit'
        disabled={page === totalPages - 1}
      >
        <CaretRight size={20} />
      </IconButton>
      <IconButton aria-label="Last Page"
        onClick={() => pageChangeHandler(totalPages - 1)}
        color='inherit'
        disabled={page === totalPages - 1}
      >
        <CaretDoubleRight size={20} />
      </IconButton>
    </Stack>
  )
}