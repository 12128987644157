import { roqMakeStyles } from 'modules/common/utils';

export const useTableNoDataStyles = roqMakeStyles(() => ({
  container: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '18px',
    padding: '48px',
  },
  heading: {
    margin: '0px',
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "166%", /* 23.24px */
    letterSpacing: "0.17px",
    color: "#0A0B0F61"
  },
}));
